// TODO: Remove this
export default function TempBingoWindow() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="layers temp-bingo-window"
    >
      <div className="container">
        <button
          style={{
            padding: 20,
            backgroundColor: "#ffffffa6",
            border: "none",
            borderRadius: 3,
          }}
          className="btn btn-primary mb-1"
          onClick={() => {
            let randNum = Math.random();
            window.open(
              `/?Token=175a4d63dd744d579763df21a37daeff&GameType=23001&NetworkID=25&GameLanguage=en&PlayerID=${randNum}&DisplayName=User19&`,
              "Fifty Fifty",
              "width=960,height=539"
            );
          }}
        >
          Open game with random player
        </button>
      </div>
    </div>
  );
}
